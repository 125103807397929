import React, { useEffect, useState } from 'react'
import {
  createStyles,
  Dialog,
  DialogContent,
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { connect } from 'react-redux'
import TextField from '../TextField/TextField'
import Button from '../Button/Button'
import StoreList from './StoreList'
import CMSText, { prismicText } from '../Global/CMSText'
import { searchWarehouseByPostcode } from '../../utils/searchWarhouseByPostcode'
import { setFullLocale } from '../../utils/utils'
import { createClient, getSingleDocument } from '../../prismicio'
import { useRouter } from 'next/router'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        borderRadius: 0,
      },
    },
    container: {
      [theme.breakpoints.up('md')]: {
        minWidth: theme.breakpoints.width('md'),
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: theme.breakpoints.width('lg'),
      },
    },
    closeButton: {
      width: '100%',
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.extraColors.greyLight,
    },
    cross: {
      cursor: 'pointer',
      height: 32,
      width: 32,
      padding: theme.spacing(0.5),
      color: theme.palette.common.white,
      backgroundColor: theme.extraColors.grey,
      borderRadius: '50%',
      margin: theme.spacing(1),
    },
    titleInHeader: {
      padding: theme.spacing(3),
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5, 1),
      },
    },
    search: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    dialogContent: {
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    searchButton: {
      margin: theme.spacing(0, 0, 2, 1),
    },
  })
)

type Tprops = {
  stores: any
  parameters: any
  t: any
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  parameters: state.parameters,
})

const InStoreModal = ({ stores, parameters, t }: Tprops) => {
  const theme = useTheme()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [postCode, setPostCode] = useState('')
  const [searchedWarehouse, setSearchedWarehouse] = useState(
    parameters?.items?.stores
  )
  const [doc, setDoc] = useState(null)
  const router = useRouter()

  const handleClose = () => {
    setOpen(false)
  }

  const searchStoreOnEnter = (event) => {
    if (event === 'Enter') {
      searchStore()
    }
  }
  const searchStore = () => {
    // call api to find stores by postal code, deactivated
    const searchedWarehouse = searchWarehouseByPostcode(
      parameters?.items?.stores,
      postCode
    )
    setSearchedWarehouse(searchedWarehouse)
  }

  useEffect(() => {
    const async = async () => {
      const client = createClient()
      const lang = setFullLocale(router.locale)
      const document = await getSingleDocument(client, lang, 'instore')
      setDoc(document)
    }
    async()
  }, [])

  const setPostCodeFunction = (value) => {
    if (value?.length <= 5) {
      setPostCode(value)
    }
  }

  useEffect(() => {
    if (stores?.selectedStore) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [stores?.selectedStore])

  return (
    <>
      <Dialog
        fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
        open={open}
        maxWidth={'lg'}
        className={classes.root}
        scroll={'paper'}
        disableBackdropClick
      >
        {doc !== null && (
          <>
            <div className={classes.container}>
              <div className={`${classes.closeButton}`}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={`${classes.titleInHeader}`}
                >
                  <CMSText
                    asText
                    data={doc?.data?.modale_title}
                    defaultText={''}
                  />
                </Typography>
              </div>
              <Divider />
              <DialogContent className={classes.dialogContent}>
                {doc?.data?.enable_postcode_search && (
                  <div className={classes.search}>
                    <TextField
                      label={
                        <CMSText
                          asText
                          data={doc?.data?.search_input_label}
                          defaultText={t('texts:instore:search_input_label')}
                        />
                      }
                      name="search-postcode"
                      placeholder={prismicText(
                        doc?.data?.search_input_placeholder,
                        t('texts:instore:search_input_placeholder')
                      )}
                      value={postCode}
                      type={'number'}
                      maxLength={5}
                      onChange={setPostCodeFunction}
                      onKeyPress={searchStoreOnEnter}
                    />
                    <Button
                      type="submit"
                      variant={'contained'}
                      className={classes.searchButton}
                      color={'secondary'}
                      size={'small'}
                      onClick={searchStore}
                      disabled={postCode === ''}
                    >
                      <CMSText
                        asText
                        data={doc?.data?.search}
                        defaultText={t('texts:instore:search')}
                      />
                    </Button>
                  </div>
                )}
                <StoreList
                  doc={doc}
                  t={t}
                  origin={stores?.deleteOrigin}
                  stores={
                    doc?.data?.enable_postcode_search
                      ? searchedWarehouse
                      : parameters?.items?.stores
                  }
                  handleClose={handleClose}
                />
              </DialogContent>
            </div>
          </>
        )}
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps)(InStoreModal)

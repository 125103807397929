import {
  Button as CoreButton,
  withStyles,
  Theme,
  ButtonTypeMap,
  ExtendButtonBase,
} from '@material-ui/core'

const Button = withStyles((theme: Theme) => ({
  root: {
    '&.MuiButton--transparent': {
      color: theme.palette.primary.contrastText,
      backgroundColor: 'rgba(255, 255, 255, .3)',
      '&:hover, &:focus, &:active, &.selected': {
        backgroundColor: 'rgba(255, 255, 255, .1)',
      },
    },
    '&.MuiButton--gradient': {
      color: theme.palette.primary.contrastText,
      backgroundColor: 'rgba(255, 255, 255, .1)',
      transition: theme.transitions.create(['transform', 'background-size']),
      background: `linear-gradient(110deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%);`,
      backgroundSize: '100%',
      '&:hover, &:focus, &:active, &.selected': {
        backgroundSize: '300%',
      },
    },
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      marginLeft: '-.75em',
      marginTop: '-.75em',
    },
  },
  sizeSmall: {},
  sizeLarge: {},
  contained: {
    color: theme.palette.primary.contrastText,
  },
  outlined: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
  outlinedSecondary: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
  containedPrimary: {
    '&:hover, &:focus, &:active, &.selected': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  containedSecondary: {
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus, &:active, &.selected': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },
  textSecondary: {
    color: theme.palette.secondary.main,
  },
}))(CoreButton)

export default Button as ExtendButtonBase<ButtonTypeMap>

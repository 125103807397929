import React from 'react'
import { Element } from '@prismicio/react'
import linkResolver from './linkResolver'
export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ')
  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}
// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function (type, element, content, children, key) {
  let props = {}
  const targetAttr = element?.data?.target
    ? { target: element.data.target }
    : {}
  const relAttr = element?.data?.target ? { rel: 'noopener' } : {}
  switch (type) {
    // Add an id to h2 tag
    case Element.heading2:
      props = {
        id: slugify(element.text),
      }
      return React.createElement('h2', propsWithUniqueKey(props, key), children)
    // Add a class to paragraph elements
    case Element.paragraph:
      props = { className: 'paragraph-class' }
      return React.createElement('p', propsWithUniqueKey(props, key), children)

    // Don't wrap images in a <p> tag
    case Element.image:
      props = { src: element.url, alt: element.alt || '' }
      return React.createElement('img', propsWithUniqueKey(props, key))

    // Add a class to hyperlinks
    case Element.hyperlink:
      props = Object.assign(
        {
          className: 'link-class',
          href: element.data.url || linkResolver(element.data),
        },
        targetAttr,
        relAttr
      )
      return React.createElement('a', propsWithUniqueKey(props, key), children)

    // Return null to stick with the default behavior
    default:
      return null
  }
}

export default htmlSerializer

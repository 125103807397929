import * as inStoreServices from '../services/inStore'

export const ACTIONS = {
  GET_STORES_BY_ORDER_NUMBER_REQUEST: 'GET_STORES_BY_ORDER_NUMBER_REQUEST',
  GET_STORES_BY_ORDER_NUMBER_SUCCESS: 'GET_STORES_BY_ORDER_NUMBER_SUCCESS',
  GET_STORES_BY_ORDER_NUMBER_FAILURE: 'GET_STORES_BY_ORDER_NUMBER_FAILURE',
  SAVE_CHOSEN_STORE: 'SAVE_CHOSEN_STORE',
  DELETE_CHOSEN_STORE: 'DELETE_CHOSEN_STORE',
}

export const GET_STORES_BY_ORDER_NUMBER_REQUEST = () => ({
  type: ACTIONS.GET_STORES_BY_ORDER_NUMBER_REQUEST,
})

export const GET_STORES_BY_ORDER_NUMBER_SUCCESS = (stores) => ({
  type: ACTIONS.GET_STORES_BY_ORDER_NUMBER_SUCCESS,
  stores,
})

export const GET_STORES_BY_ORDER_NUMBER_FAILURE = (err) => ({
  type: ACTIONS.GET_STORES_BY_ORDER_NUMBER_FAILURE,
  err,
})

export function getStoresByOrderId(orders) {
  return (dispatch) => {
    dispatch(GET_STORES_BY_ORDER_NUMBER_REQUEST())

    return inStoreServices.getStoresByOrderId(orders).then(
      (response) => dispatch(GET_STORES_BY_ORDER_NUMBER_SUCCESS(response)),
      (err) => dispatch(GET_STORES_BY_ORDER_NUMBER_FAILURE(err))
    )
  }
}

export const SAVE_CHOSEN_STORE = (selectedStore) => ({
  type: ACTIONS.SAVE_CHOSEN_STORE,
  selectedStore,
})

export function saveChosenStore(data) {
  return (dispatch) => {
    dispatch(SAVE_CHOSEN_STORE(data))
  }
}

export const DELETE_CHOSEN_STORE = (deleteOrigin) => ({
  type: ACTIONS.DELETE_CHOSEN_STORE,
  deleteOrigin,
})

export function deleteChosenStore(data) {
  return (dispatch) => {
    dispatch(DELETE_CHOSEN_STORE(data))
  }
}

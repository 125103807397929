import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { deleteChosenStore, saveChosenStore } from '../redux/actions/inStore'
import InStoreModal from './InStore/InStoreModal'

type Tprops = {
  children?: any
  stores: any
  saveChosenStore: (any) => void
  deleteChosenStore: (any) => void
  parameters: any
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  parameters: state.parameters,
})

const mapDispatchToProps = {
  saveChosenStore: saveChosenStore,
  deleteChosenStore: deleteChosenStore,
}

const InStoreManager = ({
  children,
  stores,
  saveChosenStore,
  parameters,
  deleteChosenStore,
}: Tprops) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [showModale, setShowModale] = useState(false)

  const noModalepaths = [
    '/',
    '/faq',
    '/account',
    '/register',
    '/account/informations/',
    '/confirmation',
  ]

  useEffect(() => {
    if (
      router &&
      router?.query &&
      router?.query?.store &&
      parameters?.items?.in_store
    ) {
      const queryStore = router?.query?.store
      const foundShop = parameters.items.stores.find(
        (store) => store?.slug === queryStore
      )
      if (foundShop) {
        localStorage.setItem('selectedStore', JSON.stringify(foundShop))
      } else {
        localStorage.removeItem('selectedStore')
        deleteChosenStore(router.asPath)
      }
    }
    // handle redirection when on cgl page
    if (
      typeof router?.query?.uid !== 'undefined' &&
      typeof stores?.selectedStore?.slug !== 'undefined'
    ) {
      const pagesUidParameter =
        typeof router?.query?.uid === 'string'
          ? router?.query?.uid
          : router?.query?.uid[0]
      if (
        pagesUidParameter &&
        pagesUidParameter.substring(0, 3) === 'cgl' &&
        pagesUidParameter !== `cgl-${stores?.selectedStore?.slug}` &&
        stores?.selectedStore?.slug !== null
      ) {
        router.push(`cgl-${stores?.selectedStore?.slug}`)
      }
    }
    // You can't access the website if you didn't select a store
    if (
      parameters?.items?.in_store &&
      typeof stores?.selectedStore === 'undefined' &&
      localStorage.getItem('selectedStore') === null
    ) {
      // return to HP
      if (router.pathname !== '/') {
        //router.push('/')
      }
    }
    if (
      localStorage.getItem('selectedStore') === null &&
      typeof stores?.selectedStore !== 'undefined'
    ) {
      // Save store in local storage when selected
      localStorage.setItem(
        'selectedStore',
        JSON.stringify(stores?.selectedStore)
      )
      localStorage.setItem('rmsParcelShopId', stores?.selectedStore?.code)
    }
    if (
      localStorage.getItem('selectedStore') !== null &&
      typeof stores?.selectedStore === 'undefined'
    ) {
      // When localStorage has value, refill redux store value
      saveChosenStore(JSON.parse(localStorage.getItem('selectedStore')))
    }
  }, [stores?.selectedStore, parameters?.items?.in_store])

  useEffect(() => {
    if (!noModalepaths.includes(router.pathname)) {
      setShowModale(true)
    } else {
      setShowModale(false)
    }
  }, [router.pathname])

  return (
    <>
      {parameters?.items?.in_store && showModale && <InStoreModal t={t} />}
      {children}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InStoreManager)

import * as prismic from '@prismicio/client'
import { enableAutoPreviews } from '@prismicio/next'
import { setFullLocale } from './utils/utils'

const endpoint = process.env.PRISMIC_URL

export const createClient = (config = {}) => {
  const client = prismic.createClient(endpoint)

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  })

  return client
}

export const getSingleDocument = async (client, lang, document) => {
  let data

  try {
    data = await client.getSingle(document, { lang })
  } catch {
    data = null
  }

  return data
}

export const getPrismicDocuments = async (documents, locale, previewData) => {
  const client = createClient({ previewData })
  const lang = setFullLocale(locale)

  const data = await Promise.all(
    Object.values(documents).map((document) =>
      getSingleDocument(client, lang, document)
    )
  )

  return Object.fromEntries(
    Object.keys(documents).map((key, index) => [key, data[index]])
  )
}

import React from 'react'
import { connect } from 'react-redux'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordion from '@material-ui/core/Accordion'
import { useRouter } from 'next/router'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { saveChosenStore } from '../../redux/actions/inStore'
import Button from '../Button/Button'
import CMSText from '../Global/CMSText'
import { useCart } from '../../hooks/useCart'

const mapDispatchToProps = {
  saveChosenStore: saveChosenStore,
}

const mapStateToProps = (state) => ({
  cart: state.cart,
})

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: theme.shadows[3],
    },
    header: {
      padding: theme.spacing(0.5, 2),
      backgroundColor: theme.extraColors.greyLight,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1),
      },
    },
    chooseButton: {
      padding: theme.spacing(1, 1.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: '90%',
      },
    },
    row: {
      display: 'inline-flex',
      alignItems: 'center',
      height: '100%',
    },
    storeName: {
      fontWeight: 700,
      lineHeight: 1,
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {},
    },
    toggleHeader: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(12),
    },
    toggleIcon: {
      width: '1.25rem',
      height: '1.25rem',
    },
    toggleText: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0.5),
        fontSize: '90%',
      },
    },
    cell: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '70%',
        lineHeight: 1,
        '&:last-child': {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
    },
    storeRow: {
      padding: theme.spacing(1, 0),
    },
    no_store: {
      width: '100%',
      textAlign: 'center',
    },
  })
)

const Accordion = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.extraColors.greyLight}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  content: {
    margin: theme.spacing(1, 0),
    '&$expanded': {
      margin: theme.spacing(1, 0),
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))(MuiAccordionDetails)

type Tprops = {
  stores: any
  doc: any
  t: any
  origin: any
  saveChosenStore: (any) => void
  handleClose: () => void
}

const StoreList = ({
  stores,
  saveChosenStore,
  handleClose,
  doc,
  t,
  origin,
}: Tprops) => {
  const { deleteCart } = useCart()
  const classes = useStyles()
  const router = useRouter()
  const [expanded, setExpanded] = React.useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const saveChosenStoreAndClose = async (store) => {
    await saveChosenStore(store)
    await deleteCart()
    if (origin && origin !== '/') {
      router.push(origin)
    }
    handleClose()
  }

  return (
    <>
      <div className={classes.root}>
        {stores?.length > 0 ? (
          <>
            <Grid container className={classes.header}>
              <Grid item xs={4} lg={3} className={classes.cell}>
                <CMSText
                  data={doc?.data?.modale_shop_name}
                  defaultText={t('texts:instore:modale_shop_name')}
                  asText
                />
              </Grid>
              <Grid item xs={4} lg={6} className={classes.cell}>
                <CMSText
                  data={doc?.data?.modale_informations}
                  defaultText={t('texts:instore:modale_informations')}
                  asText
                />
              </Grid>
              <Grid item xs={4} lg={3} className={classes.cell}>
                <CMSText
                  data={doc?.data?.modale_choose_shop}
                  defaultText={t('texts:instore:modale_choose_shop')}
                  asText
                />
              </Grid>
            </Grid>
            {stores?.map((store) => {
              return (
                <Accordion
                  expanded={expanded === 'panel' + store.id}
                  onChange={handleChange('panel' + store.id)}
                  key={store.id}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Grid container key={store.id} alignItems={'center'}>
                      <Grid item xs={4} lg={3} className={classes.cell}>
                        <Typography
                          component={'h2'}
                          variant={'body2'}
                          className={classes.storeName}
                        >
                          {store.name}
                        </Typography>
                        {store.distance && (
                          <Typography variant={'body2'} color={'secondary'}>
                            {store.distance}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4} lg={6} className={classes.cell}>
                        <div className={classes.row}>
                          <div className={classes.toggleHeader}>
                            {expanded === 'panel' + store.id ? (
                              <RemoveCircleIcon
                                className={classes.toggleIcon}
                                color={'primary'}
                              />
                            ) : (
                              <AddCircleIcon
                                className={classes.toggleIcon}
                                color={'primary'}
                              />
                            )}
                            <Typography
                              variant={'body2'}
                              className={classes.toggleText}
                            >
                              <CMSText
                                asText
                                data={doc?.data?.modale_details}
                                defaultText={t('texts:instore:modale_details')}
                              />
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4} lg={3} className={classes.cell}>
                        <Button
                          type="submit"
                          className={classes.chooseButton}
                          variant={'contained'}
                          color={'primary'}
                          size={'small'}
                          onClick={() => saveChosenStoreAndClose(store)}
                        >
                          <CMSText
                            asText
                            data={doc?.data?.modale_choose_button}
                            defaultText={t(
                              'texts:instore:modale_choose_button'
                            )}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container key={store.id}>
                      <Grid item xs={12} lg={3}></Grid>
                      <Grid item xs={12} lg={6}>
                        <Typography component={'h3'} variant={'h5'}>
                          <b>
                            {' '}
                            <CMSText
                              asText
                              data={doc?.data?.modale_address}
                              defaultText={t('texts:instore:modale_address')}
                            />
                          </b>
                        </Typography>
                        <Typography component={'p'} variant={'body2'}>
                          {store.address}
                        </Typography>
                        <Typography component={'p'} variant={'body2'}>
                          {store.address_details}
                        </Typography>
                        <Typography component={'p'} variant={'body2'}>
                          {store.city +
                            ' ' +
                            store.zipcode +
                            ' ' +
                            (store.region !== null ? store.region : '')}
                        </Typography>
                        <Typography component={'h3'} variant={'h5'}>
                          <b>
                            {' '}
                            <CMSText
                              asText
                              data={doc?.data?.modale_phone}
                              defaultText={t('texts:instore:modale_phone')}
                            />
                          </b>
                        </Typography>
                        <Typography component={'p'} variant={'body2'}>
                          {store.phone}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </>
        ) : (
          <div className={classes.no_store}>
            <Typography component={'div'} variant={'body2'}>
              <CMSText
                data={doc?.data?.no_store_available}
                defaultText={t('instore:no_store_available')}
              />
            </Typography>
          </div>
        )}
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreList)

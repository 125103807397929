import { apiHeaders } from '../../utils/utils'
import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'

export const getStoresByOrderId = (orders) =>
  axiosWrapper({
    method: EMethods.post,
    url: ERequestUrl.warehouseOrder,
    headers: apiHeaders(localStorage.getItem('connexion-type')),
    data: {
      orders,
    },
  })
